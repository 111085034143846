import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(
        /* webpackChunkName: "Home view" */ "../views/ExperienceCentre.vue"
      ),
  },
  {
    path: "/lead/dashboard",
    name: "LeadDashboard",
    component: () =>
      import(
        /* webpackChunkName: "Home view" */ "../views/LeadDashboardView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 10,
      behavior: "smooth",
    };
  },
});

export default router;
