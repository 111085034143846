import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "jquery";
import { Plugin } from "vue-responsive-video-background-player";

createApp(App).use(store).use(router).use(Plugin).mount("#app");
